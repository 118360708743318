import type { BlockOptionDefinition } from '#blokkli/types/blokkOptions'

type GlobalOptionsDefaults = {
  type: BlockOptionDefinition['type']
  default: any
}

export const bundlesWithVisibleLanguage: string[] = ["focus_teaser","cleverreach_form","button","audio_player","dynamic_teaser_list","event_list","gallery","csd_service","expand_section","external_contact","iframe","government_contact","image","information_link_attachment","link_list","next_best_action","mapbs_iframe","logo_pictogramm","popular_links","place","publication_list","quote","table","teaser","section_title","video_remote","text","teaser_list"]
export const bundlesWithHiddenGlobally: string[] = []

export const globalOptionsDefaults: Record<string, GlobalOptionsDefaults> = {
  "bkVisibleLanguages": {
    "default": [],
    "type": "checkboxes"
  },
  "teaserStyle": {
    "default": "normal",
    "type": "radios"
  },
  "showInToc": {
    "default": true,
    "type": "checkbox"
  }
} as const